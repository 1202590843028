import { Link } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from '../../components/Layout/Layout'
import {links} from '../../components/Menu/Menu'

// markup
const TherapistLink = links.find((v) => v.url.includes('therapists'))

const IndexPage = () =>
(
    <Layout>
        <Helmet title="Therapists" defer={false} />
        <ul>
        {
            TherapistLink
                ?.links
                ?.map((therapist, i) => (
                    <li key={i}>
                        <Link to={TherapistLink.url + therapist.url}>
                            {therapist.name}
                        </Link>
                    </li>
                ))
        }
        </ul>
    </Layout>
)

export default IndexPage
